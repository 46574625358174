<template>
	<edit-template class="AddRole" @confirm="onSubmit" @cancel="$router.back()" :cancelRemind="false" confirmText="立即创建" cancelText="取消">

		<div class="form-body">
			<el-form ref="form" :model="form" label-width="80rem">
				<el-form-item label="角色名称" prop="name" :rules="[{ type: 'string', max: 20, message: '角色名称最多不能超过20个字符' },{required:true,message:'请输入角色名称'}]">
					<el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
				</el-form-item>
				<el-form-item label="角色类型" prop="type" :rules="[{required: true,message:'请选择角色类型'}]">
					<el-select :popper-append-to-body="false" v-model="form.type" @change="changeRoleType" placeholder="请选择角色类型">
						<el-option v-for="item in roleType" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单权限" :rules="[{required:true}]">
					<el-button @click="openSelectMenu">点击选择</el-button>
					<div>
						<el-tag :key="tag.id" v-for="(tag, index) in menuSelected" closable="" v-if="tag.tier > 1" :disable-transitions="false" @close="handleMenuSelectClose(0, tag, index)" style="margin-right: 10rem;">
							{{ tag.name }}
						</el-tag>
					</div>
				</el-form-item>
				<div v-if="dataJur !== 0">
          <h3 style="margin: 20rem 0">数据权限</h3>
          <el-form-item label="校区" :rules="[{required:true}]">
            <el-button @click="openSelectCampus">点击选择</el-button>
            <!--          <span class="t-color-font-2" style="margin-left: 10rem;"
                  v-if="campusSelected.length>0">已选中{{ campusSelected.length }} 项</span>-->
            <div>
              <el-tag :key="tag.id" v-for="(tag, index) in campusSelectedMap" closable="" style="margin-right: 10rem" :disable-transitions="false" @close="handleMenuSelectClose(1, tag, index)">
                {{ tag.school_name }}
              </el-tag>
            </div>
          </el-form-item>
          <el-form-item label="范围" v-if="dataJur === 2" :rules="[{required:true}]">
            <el-button @click="openSelectArea">点击选择</el-button>
            <!--          <span class="t-color-font-2" style="margin-left: 10rem;"
                  v-if="areaSelected.length>0">已选中{{ areaSelected.length }} 项</span>-->
            <div>
              <el-tag :key="tag.id" v-for="(tag, index) in areaSelected" v-if="tag.tier > 2" closable="" style="margin-right: 10rem" :disable-transitions="false" @close="handleMenuSelectClose(2, tag, index)">
                {{ tag.name }}
              </el-tag>
            </div>
          </el-form-item>
        </div>
			</el-form>
		</div>

		<el-dialog title="配置菜单权限" :visible.sync="menuConfigVisible" @open="dialogState = 0" @close="onSelectedMenu" width="80%">
			<div class="menuBox">
				<div class="menu" v-if="menuConfig.length!==0" v-for="(menu_1, index_1) in menuConfig">
					<el-divider content-position="left"><b style="font-size: 18rem">{{ menu_1.name }}</b></el-divider>
					<el-checkbox-group v-model="menu_1.selected" @change="handleMenuConfigChange($event, menu_1, index_1)">
						<!--            <div
                style="border-left: 8rem solid #F9660D;display: flex;align-items: center;margin-bottom: 10rem;padding-left: 10rem;">
              <el-checkbox :label="menu_1.selected"
              >全选
              </el-checkbox>
            </div>-->
						<div>
							<el-checkbox-group style="margin: 20rem 10rem 0 0" v-for="(menu_2, index_2) in menu_1.child" v-model="menu_2.selected" @change="handleMenuConfigChange($event, menu_2, index_1 + ',' + index_2)">
								<div style="
										border-left: 8rem solid #f6ad3c;
										display: flex;
										align-items: center;
										margin-left: 20rem;
										margin-bottom: 10rem;
										padding-left: 10rem;
									">
                  <span style="font-size: 14rem;">{{menu_2.name}}</span>

                </div>
								<div style="
										border-left: 8rem solid #fad17f;
										display: flex;
										align-items: center;
										margin-left: 40rem;
										margin-bottom: 10rem;
										padding-left: 10rem;
									">
									<el-checkbox-group style="display: inline-block; margin: 5rem" v-if="menu_2.child != null" v-for="(menu_3, index_3) in menu_2.child" v-model="menu_3.selected" @change="handleMenuConfigChange($event, menu_3, index_1 + ',' + index_2 + ',' + index_3)">
										<el-checkbox :label="menu_3.selected">{{ menu_3.name }} </el-checkbox>
									</el-checkbox-group>
								</div>
							</el-checkbox-group>
						</div>
					</el-checkbox-group>
				</div>
        <el-empty v-if="menuConfig.length === 0" :image-size="200"></el-empty>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="
						dialogState = -1
						onSelectedMenu()
					">重 置</el-button>
				<el-button type="primary" @click="
						menuConfigVisible = false
						dialogState = 1
					">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="选择校区" @open="dialogState = 0" :visible.sync="campusVisible" @close="onSelectedCampus" width="80%">
			<div class="campus">
				<div v-if="campusList.length > 0">
          <el-checkbox v-model="campusAll" @change="checkAllCampus" style="padding-bottom: 8rem"><b>全选</b></el-checkbox>
          <el-checkbox-group v-model="campusSelected" @change="changeSelectedCampus">
            <el-checkbox v-for="item in campusList" style="display: block; padding: 8rem 0" :label="item.id" :key="item.id">
              {{ item.school_name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>

        <el-empty v-if="campusList.length === 0" :image-size="200"></el-empty>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="
						dialogState = -1
						onSelectedCampus()
					">重 置</el-button>
				<el-button type="primary" @click="
						dialogState = 1
						campusVisible = false
					">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="选择范围" @open="dialogState = 0" :visible.sync="areaVisible" @close="onSelectedArea" width="80%">
			<div v-if="areaList.length!==0" v-for="(select_1, index_1) in areaList">
				<el-divider><h2>{{ select_1.name }}</h2></el-divider>
				<div v-for="(selected_2, index_2) in select_1.data">
					<h3 style="margin: 10rem 0">{{ selected_2.name }}</h3>

					<el-checkbox-group v-for="(selected_3, index_3) in selected_2.data" v-model="selected_3.selected" style="margin-bottom: 10rem" @change="handleCheckedGroup(selected_3, index_1 + ',' + index_2 + ',' + index_3)">
						<div style="border-left: 8rem solid #f9660d; display: flex; align-items: center; margin-bottom: 10rem; padding-left: 10rem">
							<el-checkbox :label="selected_3.selected" style="">{{ selected_3.name }}</el-checkbox>
						</div>

						<el-checkbox-group v-for="(selected_4, index_4) in selected_3.data" v-model="selected_4.selected" style="margin-bottom: 10rem" @change="handleCheckedGroup(selected_4, index_1 + ',' + index_2 + ',' + index_3 + ',' + index_4)">
							<div style="
									margin-left: 20rem;
									border-left: 8rem solid #f6ad3c;
									display: flex;
									align-items: center;
									margin-bottom: 10rem;
									padding-left: 10rem;
								">
								<el-checkbox :label="selected_4.selected">{{ selected_4.name }}</el-checkbox>
							</div>

							<el-checkbox-group v-for="(selected_5, index_5) in selected_4.data" v-model="selected_5.selected" style="margin-bottom: 10rem" @change="handleCheckedGroup(selected_5, index_1 + ',' + index_2 + ',' + index_3 + ',' + index_4 + ',' + index_5)">
								<div style="
										border-left: 8rem solid #f8bf5d;
										margin-left: 40rem;
										display: flex;
										align-items: center;
										margin-bottom: 10rem;
										padding-left: 10rem;
									">
									<el-checkbox :label="selected_5.selected">{{ selected_5.name }}</el-checkbox>
								</div>

								<div style="
										border-left: 8rem solid #fad17f;
										margin-left: 60rem;
										display: flex;
										flex-wrap: wrap;
										align-items: center;
										margin-bottom: 10rem;
										align-items: center;
										padding-left: 10rem;
									">
									<el-checkbox-group v-for="(selected_6, index_6) in selected_5.data" v-model="selected_6.selected" style="display: inline-block; margin: 5rem 5rem" @change="
											handleCheckedGroup(
												selected_6,
												index_1 + ',' + index_2 + ',' + index_3 + ',' + index_4 + ',' + index_5 + ',' + index_6
											)
										">
										<el-checkbox :label="selected_6.selected">{{ selected_6.class_name }}</el-checkbox>
									</el-checkbox-group>
								</div>
							</el-checkbox-group>
						</el-checkbox-group>
					</el-checkbox-group>
				</div>
			</div>
      <el-empty v-if="areaList.length === 0" :image-size="200"></el-empty>
      <div slot="footer" class="dialog-footer">
				<el-button @click="
						dialogState = -1
						onSelectedArea()
					">重 置</el-button>
				<el-button type="primary" @click="
						dialogState = 1
						areaVisible = false
					">确 定</el-button>
			</div>
		</el-dialog>

</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			form: {
				name: '',
				type: null
			},
			dialogState: -1,
			// 创建按钮的加载状态
			loading: false,
			// 菜单配置弹窗
			menuConfigVisible: false,
			// 菜单配置
			menuConfig: [],
			// 菜单选中权限
			menuSelected: [],
			// 菜单选中权限备份
			menuConfigBackup: [],
			// 角色类型
			roleType: [],
			// 校区弹窗
			campusVisible: false,
			// 校区列表
			campusList: [],
			// 校区全选
			campusAll: false,
			// 校区选中
			campusSelected: [],
			// 校区选中备份
			campusSelectedBackup: [],
			// 校区选中对象映射
			campusSelectedMap: [],
			// 范围弹窗
			areaVisible: false,
			// 范围数据列表
			areaList: [],
			// 范围选中数据
			areaSelected: [],
			// 范围选中数据的备份
			areaSelectedBackup: [],
			selectedMenuItem: [[], []]
		}
	},
	computed: {
    /**
     * @desc 数据权限的展示与否
     * @return {number} 0 : 不展示 1: 只展示校区 2: 校区和范围全展示
     */
    dataJur(){
      let {name,type} = this.form;
      type = parseInt(type);
      /**
       * {
       *     "1": "学生",
       *     "2": "教职工",
       *     "3": "校区子管理员",
       *     "4": "招生老师",
       *     "5": "招生运营",
       *     "6": "财务会计"
       * }
       */
      switch (type){
        case 1:return 2;
        case 2:return 2;
        case 3:return 2;
        case 4:return 0;
        case 5:return 0;
        case 6:return 1;
        default:return 0;
      }
    }
  },
	mounted() {
		this.init()
	},
	methods: {
    /** 当角色类型被更改*/
	  changeRoleType(){
	    this.menuConfig=[];
	    this.menuSelected=[];
	    this.menuConfigBackup=[];
	    this.campusSelected = [];
	    this.campusSelectedBackup = [];
	    this.campusSelectedMap = [];
	    this.campusAll = [];
      this.areaSelected = [];
      this.areaSelectedBackup = [];
      this.areaList = [];
      // 获取菜单配置
      this.$utils.roleApi.getMenuConfigList(this.form.type).then(res => {
        let { data } = res.data
        let item = this.$tools.eachCheckboxTree(data, {
          child: 'child',
          isFirst: true,
          selected: false
        })
        this.menuConfig = Object.assign(item)
      })
    },
    // 提交
		onSubmit() {
			let { name, type } = this.form,
				menu = this.menuSelected.map(item => item.id),
				ids = this.areaSelected.map(item => item.id),
        schools = this.campusSelected;
			if (!name) {
				return this.$message.warning('请输入角色名称')
			} else if (type == null) {
				return this.$message.warning('请选择角色类型')
			} else if (menu.length === 0) {
				return this.$message.warning('请选择菜单权限')
			}
      if (!schools.length && this.dataJur === 1){
        return this.$message.warning("请选择校区");
      }else if (ids.length === 0 && this.dataJur === 2) {
        return this.$message.warning('请选择范围');
      }
      if(this.dataJur === 1)ids = schools;
			this.loading = true
			this.$_axios
				.post('/role/add', {
					name,
					type,
					permit_menu: menu,
					permit_data: ids
				})
				.then(res => {
					this.loading = false
					let { errorUserMsg } = res.data.error
					this.$message.success({ message: errorUserMsg, showClose: true })
          this.$store.commit("setPage",1);
					this.$router.back()
				})
				.catch(_ => (this.loading = false))
		},
		handleMenuConfigChange(selected, item, indexes) {
			this.$tools.DFS(
				item,
				cont => {
					cont.selected = selected
				},
				{ child: 'child' }
			)
			let setParent = data => {
				if (!data) return
				let parent = data.parent
				if (!parent) return
				let siblings = parent.child
				if (selected) {
					parent.selected = selected
				} else {
					let flag = false
					for (let i = 0; i < siblings.length; i++) {
						let child = siblings[i]
						if (child.selected) {
							flag = true
							break
						}
					}
					parent.selected = flag
				}
				setParent(parent)
			}
			setParent(item)
			this.$forceUpdate()
		},
		/**
		 * @description 打开菜单权限选择框
		 * */
		openSelectMenu() {
		  if (!this.form.type)return this.$message.warning("请选择角色类型")
			this.menuConfigBackup = this.menuSelected.concat([])
			this.menuConfigVisible = true
		},
		onSelectedMenu() {
			let state = this.dialogState
			switch (state) {
				case -1:
					this.$tools.DFS(
						this.menuConfig,
						item => {
							item.selected = false
						},
						{ child: 'child' }
					)
					this.menuSelected = []
					this.dialogState = 0
					break
				case 0:
					let backup = this.menuConfigBackup
					this.menuSelected = backup
					let menu = backup.map(item => item.id)
					this.$tools.DFS(
						this.menuConfig,
						item => {
							item.selected = menu.indexOf(item.id) !== -1
						},
						{ child: 'child' }
					)
					break
				case 1:
					let node = this.menuConfig
					let ids = []
					this.$tools.DFS(
						node,
						item => {
							if (item.selected && item.id != null) {
								ids.push({
									id: item.id,
									name: item.name,
									tier: item.tier
								})
							}
						},
						{ child: 'child' }
					);
					let ids_p = [], selected_p = [],flag = true;
          ids.forEach(item=>{
            if (item.tier === 0)ids_p.push(item.id);
          })
          this.menuSelected.forEach(item=>{
            if (item.tier === 0)selected_p.push(item.id);
          });
          if (ids_p.length !== selected_p.length) flag = false;
          if (flag)
            for (let i = 0;i<selected_p.length;i++){
              let item = selected_p[i];
              if (ids_p.indexOf(item) === -1){
                flag = false;
                break;
              }
            }
          if (ids_p.length===0)flag = false;

          if (!flag){
            this.campusSelected = [];
            this.campusSelectedBackup = [];
            this.campusSelectedMap = [];
            this.campusAll = [];
            this.areaSelected = [];
            this.areaSelectedBackup = [];
            this.areaList = [];
          }
					this.menuSelected = ids
					break
			}
		},
		/**
		 *  @description 打开范围选择框
		 * */
		openSelectArea() {
      if (this.campusSelected.length === 0) return this.$message.warning('请先选择校区');
      this.areaSelectedBackup = this.areaSelected.slice(0);
      // let backup = this.areaSelectedBackup
      // this.areaSelected = backup
      let area = this.areaSelectedBackup.map(item => item.id)
      this.$tools.DFS(this.areaList, item => {
        item.selected = area.indexOf(item.id) !== -1;
      })
      this.areaVisible = true;
		},
		/**
		 * @description 打开选择校区选择框
		 * */
		openSelectCampus() {
		  if (this.menuSelected.length ===0)return this.$message.warning("请选择菜单权限")
			this.campusSelectedBackup = JSON.parse(JSON.stringify(this.campusSelected))
			this.campusVisible = true
		},
		/**
		 * @description 选择校区复选框被更改
		 * @param value
		 */
		changeSelectedCampus(value) {
			let checkedCount = value.length
			this.campusAll = checkedCount === this.campusList.length
		},
		onMapCampus() {
			let tempMap = []
			for (let i = 0; i < this.campusList.length; i++) {
				let item = this.campusList[i]
				if (this.campusSelected.indexOf(item.id) !== -1) tempMap.push(item)
			}
			this.campusSelectedMap = tempMap
		},
		/**
		 * @description 确认或者取消选择校区
		 * */
		onSelectedCampus() {
			let state = this.dialogState
			switch (state) {
				case -1:
					this.campusSelected = []
					this.campusSelectedMap = []
					this.campusAll = false
					this.dialogState = 0
					break
				case 0:
					this.campusSelected = this.campusSelectedBackup
					this.onMapCampus()
					break
				case 1:
          let campus ="";
          let selected = [];
          for (let i in this.campusList){
            let item = this.campusList[i];
            if (this.campusSelected.indexOf(item.id) !== -1) {
              campus += item.id+',';
              selected.push(item.id);
            }
          }
          campus = campus.substr(0,campus.length-1);
          let menu_ids = [];
          this.menuSelected.forEach(item=>{
            if (item.tier === 0)menu_ids.push(item.id);
          })
          this.campusSelected = selected;
					this.onMapCampus()
					if (campus.length > 0) {
						this.$utils.roleApi.getClassesList(campus,menu_ids.join(",")).then(res => {
							let { data } = res.data
							this.areaList = this.$tools.eachCheckboxTree(data, {
								isFirst: true,
								selected: false
							})
						})
					}
          for (let i in this.campusSelectedBackup){
            let item = this.campusSelectedBackup[i];
            if (this.campusSelected.indexOf(item) === -1){
              this.deleteCampus(item);
            }
          }
					break
			}
			this.campusAll = this.campusSelected.length === this.campusList.length
		},
		/**
		 * @description 全选校区
		 * @param {boolean} val
		 */
		checkAllCampus(val) {
			this.campusSelected = val ? this.campusList.map(item => item.id) : []
		},
		/**
		 * @description  操作多选框组
		 * @param item
		 * @param indexes
		 */
		handleCheckedGroup(item, indexes) {
			this.$tools.handleCheckedGroup(item, 'data', indexes)
		},

		onSelectedArea() {
			let state = this.dialogState
			switch (state) {
				case -1:
					this.$tools.DFS(this.areaList, item => {
						item.selected = false
					})
					this.areaSelected = []
					this.dialogState = 0
					break
				case 0:
					let backup = this.areaSelectedBackup
					this.areaSelected = backup
					let area = backup.map(item => item.id)
					this.$tools.DFS(this.areaList, item => {
						item.selected = area.indexOf(item.id) !== -1
					})
					break
				case 1:
					let data = this.areaList
					let ids = []
					this.$tools.DFS(data, item => {
						if (item.selected)
							ids.push({
								id: item.id,
								name: item.name || item.class_name,
								tier: item.tier
							})
					})
					this.areaSelected = ids
					break
			}
			// this.areaVisible = false;
		},
		/**
		 * @description 初始化数据
		 */
		init() {
			// 获取角色类型列表
			this.$utils.roleApi.getRoleType().then(res => {
				let { data } = res.data
				let arr = []
				for (let k in data) {
					arr.push({
						value: k,
						label: data[k]
					})
				}
				this.roleType = arr
			})

			// 获取校区
			this.$utils.roleApi.getCampusList().then(res => {
				let { data } = res.data
				this.campusList = data
			})
		},
    /**
     * @description 删除(取消选中)校区时的数据变更渲.染
     * */
    deleteCampus(id){
      let selected = [];
      this.areaSelected.forEach((item,index)=>{
        let school_id = item.id.split("-")[1];
        if (school_id !== id){
          selected.push(item);
        }
      })
      this.areaSelected = selected;
    },
		/**
		 * @description 操作tag
		 * @param {number}type
		 * @param {Object}tag
		 * @param {number}index
		 */
		handleMenuSelectClose(type, tag, index) {
			let data, source, child, ids
			switch (type) {
				case 0:
					data = this.menuSelected
					source = this.menuConfig
					child = 'child'
					break
				case 1:
					data = this.campusSelected
					source = this.campusList
					break
				case 2:
					data = this.areaSelected
					source = this.areaList
					child = 'data'
					break
			}
			// data.splice(index,1);
			ids = data.map(item => item.id)
			if (type === 0) {
				let cur = data[index]
				this.$tools.DFS(
					source,
					item => {
						if (item.id === cur.id) {
							this.handleMenuConfigChange(false, item)
						}
					},
					{ child }
				)
				this.dialogState = 1
				this.onSelectedMenu()
				this.dialogState = 0
			} else if (type === 1) {
        let id = this.campusSelectedMap[index].id;
        data.splice(data.indexOf(id),1);
			  this.campusAll = this.campusSelected.length === this.campusList.length
        // 删除校区过后 , 同时删除校区下的范围数据
        this.deleteCampus(id);
        // 获取新的校区下的范围数据
        let campus = this.campusSelected.join(',')
        let menu_ids = [];
        this.menuSelected.forEach(item=>{
          if (item.tier === 0)menu_ids.push(item.id);
        })
        this.onMapCampus();
				if (campus.length > 0) {
					this.$utils.roleApi.getClassesList(campus,menu_ids.join(",")).then(res => {
						let { data } = res.data
						this.areaList = this.$tools.eachCheckboxTree(data, {
							isFirst: true,
							selected: false
						})
					})
				}
				this.$tools.DFS(this.areaList, item => {
					item.selected = false
				})
			} else if (type === 2) {
				let cur = data[index]
				this.$tools.DFS(
					source,
					item => {
						if (item.id === cur.id) {
							item.selected = false
							this.handleCheckedGroup(item)
						}
					},
					{ child }
				)
				this.dialogState = 1
				this.onSelectedArea()
				this.dialogState = 0
				return
			}
			this.$forceUpdate()
		}
	}
}
</script>

<style type="text/css" lang="scss" scoped>
.AddRole {
  padding: 20rem;
  min-height: calc(100% - 20rem);

  .addRoleForm {
    width: 50%;
    min-width: 400rem;
    margin: calc(100vh / 11.7125) auto 0;
    .el-form-item {
      margin-bottom: 30rem;
    }
  }
  ::v-deep .el-dialog__body {
    max-height: 65vh;
    overflow: auto;
  }
}
</style>
